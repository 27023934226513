<template>
  <v-dialog v-model="dialog" max-width="750px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <div>
          <v-card-text>
            <h2 class="custom-title-secondary mb-1"
              >Está precisando de mais alguma informação?</h2
            >
            <p class="custom-normal-text">
              Você pode contar com a ajuda da nossa equipe por chat, telefone
              (11) 4130-7932 ou pelo e-mail contato@simplificador.com.br. <br />
              Caso queira agendar uma ligação, informe o melhor dia e horário,
              que entraremos em contato pelo telefone indicado. feira.
            </p>
            <p class="custom-normal-text text-blue font-weight-bold">
              Nosso horário de atendimento é das 10h às 12h e das 13h às 17h, de
              segunda a sexta
            </p>
          </v-card-text>
          <v-card-text>
            <diaries :typeId="2"></diaries>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="custom-btn-green-inverse rounded-0"
            text
            @click="closeDialogs()"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import diaries from '@src/components/diaries/diaries.vue'

export default {
  props: {
    dialogShow: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  components: {
    diaries,
  },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      form: {
        name: null,
        phone: '',
        period: '',
        date: '',
      },
      rules: {
        phone: [
          (v) =>
            v.length > 9 || 'O número de telefone precisa ter mais 10 digitos.',
          (v) => !!v || 'Por favor preencha o telefone.',
        ],
        date: [(v) => !!v || 'Data é obrigatorio!'],
      },
    }
  },
  computed: {
    ...mapGetters({
      schedulingCall: 'formDataSchedulingCall/schedulingCall',
    }),
  },
  methods: {
    closeDialogs() {
      this.$emit('close', false)
    },
  },
  watch: {
    dialogShow(newValue) {
      this.dialog = newValue
    },
    dialog(newValue, oldValue) {
      if (!newValue) this.$emit('close', false)
    },
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
